<template>
    <div class="text-center">
        <v-dialog v-model="dialog" width="700">
            <v-card>
                <v-card-subtitle>
                    Mesa
                </v-card-subtitle>

                <v-card-text>
                    
                        <v-container>
                            <v-row justify="center">
                                <v-col cols="12" md="4" v-for="(item, i) in FiltroEstado()" :key="i"  >
                                    <v-item>
                                      
                                        <v-card :color="item.color" class="d-flex align-center"
                                            height="70" @click="seleccionar(i)">
                                          
                                            <v-scroll-y-transition>
                                                <div class=" text-h5 flex-grow-1 text-center"
                                                    :class="select == i ? 'white--text ' : 'white--text'">
                                                    {{ item.descripcion }}
                                                </div>
                                            </v-scroll-y-transition>
                                        </v-card>
                                    </v-item>
                                </v-col>

                            </v-row>
                        </v-container>
                     
                    <v-divider></v-divider>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" small @click="dialog = false">
                        Cerrar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { ref } from '@vue/composition-api'
export default {
    setup() {
        const dialog = ref(false)
        const mesa = ref({})
        const estatus = ref([
            {
                id: 52,
                descripcion: "LIBERAR",
                color:'success',
                idPadre: [53, 54]
            },
            {
                id: 54,
                descripcion: "RESERVAR",
                idPadre: [52],
                color:'primary',
            },
            {
                id: 53,
                descripcion: "BLOQUEAR",
                idPadre: [52],
                color:'error',
            }
        ])
        const abrir = (dato) => {
            dialog.value = true
            mesa.value = dato
        }

        const FiltroEstado =(item)=>{
            return estatus.value.filter(item=>item.idPadre.includes(mesa.value.estado_disponibilidad))
        }
        
        return {
            dialog,
            abrir,
            mesa,
            estatus,
            FiltroEstado
        }
    },
}
</script>