<template>
  <v-card>
    <v-card-text>
      <v-col cols="12" v-if="GrupoSeleccionada.id == undefined">
        <v-row>
          <v-col lg="2" md="2" sm="4" cols="6" v-for="(item, key) in grupos" :key="key">
            <a ref="#" v-on:click="SeleccionarGrupo(item)">
              <v-card>
                <v-img
                  :src="require(`@/assets/images/logos/${logoI}`)"
                  :gradient="`to bottom, rgba(${item.color},.5), rgba(${item.color})`"
                  height="100px"
                >
                  <span class="text-img-catgoria pt-8">
                    <small>{{ item.descripcion }}</small> <br />
                    <small>
                      <v-icon small color="white">{{ icons.mdiCheckCircle }}</v-icon>
                      {{ mesasDisponibles(item) }}</small
                    >
                  </span>
                </v-img>
              </v-card>
            </a>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" v-if="GrupoSeleccionada.id != undefined">
        <v-row>
          <v-col lg="2" md="2" sm="4" cols="6">
            <v-card
              :gradient="`to bottom, rgba(255, 4, 31), rgba(255, 4, 31,.8) `"
              class="d-flex align-center"
              dark
              height="100"
              @click="SeleccionarGrupo(null)"
            >
              <v-scroll-y-transition>
                <div :gradient="`to bottom, rgba(255, 4, 31), rgba(255, 4, 31,.8) `" class="flex-grow-1 text-center">
                  <span>Atras</span>
                </div>
              </v-scroll-y-transition>
            </v-card>
          </v-col>
          <v-col lg="2" md="2" sm="4" cols="6" v-for="(item, key) in mesas" :key="key" class="mt-3" > 
            <v-card @click="SeleccionarMesa(item)" :class="colorEstadoDisponibilidad2(item.estado_disponibilidad)" >
              <v-row>
                <v-col     cols="4" style="height: 100px;" class="  text-center"  > 
                    <v-img    src="@/assets/images/mesas/mesa_4.png" width="50px"> </v-img>
                    <small>
                      <v-icon small>{{ icons.mdiSeatOutline }}</v-icon> {{ item.capacidad }}</small
                    >
                </v-col>
                <v-divider vertical></v-divider>
                <v-col  >
                  <span>
                    <span class="text-center">{{ item.nombre }}</span>
                    <br />
                     
                    <small>
                      {{ item.estado_disponibilidad_json.descripcion }}
                    </small>
                    <br />
                    <small>{{ item.comandas }}</small>
                  </span>
                </v-col >
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-card-text>
    <ModalMesa ref="ModalMesaRef"></ModalMesa>
  </v-card>
</template>

<script>
import MaestroService from '@/api/servicios/MaestroService'
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import ModalMesa from './ModalMesa.vue'
import { mdiSeatOutline, mdiCheckCircle } from '@mdi/js'
export default {
  props: {
    label: {
      type: String,
      default: 'Seleccionar mesa',
    },
  },
  components: {
    ModalMesa,
  },
  setup(props, context) {
    const logoI = process.env.VUE_APP_IMG_LOGO
    const ModalMesaRef = ref(null)
    onBeforeMount(() => {
      cargarMesas()
      SeleccionarGrupo(null)
    })

    let counterConsultas = ref(0)
    watch(counterConsultas, () => {
      setTimeout(() => {
        cargarMesas()
      }, 1000)
    })
    const GrupoSeleccionada = ref({
      id: null,
    })
    const MesaSeleccionada = ref({
      id: null,
    })
    const grupos = ref([])
    const dialog = ref(false)
    const mesas = ref([])
    watch(dialog, () => {
      if (dialog.value == true) {
        SeleccionarGrupo(null)
        cargarMesas()
      }
    })

    const mesasDisponibles = grupo => {
      const x = grupo.mesa.filter(it => it.estado_disponibilidad == 52)?.length

      return x
    }

    const colorEstadoDisponibilidad = id => {
      switch (id) {
        case 52:
          return 'rgba(0, 186, 48), rgba(0, 186, 48)'
          break
        case 53:
          return 'rgba(215, 0, 0 ), rgba(215, 0, 0 )'
          break
        case 54:
          return 'rgba(212, 215, 0 ), rgba(212, 215, 0 )'
          break
        case 55:
          return 'rgba(0, 213, 191 ), rgba(0, 213, 191 )'
          break
        case 56:
          return 'rgba(0, 68, 213 ), rgba(0, 68, 213 )'
          break
        default:
          return 'rgba(0, 0, 0), rgba(0, 0, 0)'
          break
      }
    }

    
    const colorEstadoDisponibilidad2 = id => {
      switch (id) {
        case 52:
          return 'primary'
          break
        case 53:
          return 'info'
          break
        case 54:
          return 'warning'
          break
        case 55:
          return 'warning'
          break
        case 56:
          return 'error'
          break
        default:
          return 'primary'
          break
      }
    }
    const cargando = ref(false)
    const cargarMesas = () => {
      cargando.value = true
      try {
        MaestroService.GruposMesasListar()
          .then(response => {
            if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
              grupos.value = response.data.datos
              if (GrupoSeleccionada.value.id) {
                mesas.value = grupos.value.find(it => it.id == GrupoSeleccionada.value.id).mesa
                console.log(mesas.value)
              }
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            cargando.value = false
            counterConsultas.value += 1
          })
      } catch (error) {
        cargando.value = false
        counterConsultas.value += 1
      }
    }

    const SeleccionarGrupo = item => {
      if (item != null) {
        GrupoSeleccionada.value = item
        mesas.value = item.mesa
        MesaSeleccionada.value = {}
      } else {
        GrupoSeleccionada.value = {}
      }
    }

    const SeleccionarMesa = item => {
      if (item != null) {
        MesaSeleccionada.value = item
        ModalMesaRef.value.abrir(item)
      } else {
        MesaSeleccionada.value = {}
      }
    }

    const GetMesa = item => {
      context.emit('GetMesa', item)
    }
    return {
      cargarMesas,
      dialog,
      grupos,
      cargando,
      GrupoSeleccionada,
      MesaSeleccionada,
      SeleccionarGrupo,
      mesas,
      SeleccionarMesa,
      GetMesa,
      ModalMesaRef,
      colorEstadoDisponibilidad,
      colorEstadoDisponibilidad2,
      mesasDisponibles,
      logoI,
      icons: {
        mdiSeatOutline,
        mdiCheckCircle,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.text-img-catgoria {
  height: 100%;
  color: white;
  position: absolute;
  text-align: center;
  justify-content: center;
  width: 100%;
  background-image: linear-gradient(rgb(0, 0, 0), rgba(10, 10, 10, 0.445));
}

.text-img-catgoria-2 {
  height: 100%;
  position: absolute;
  text-align: center;
  width: 100%;
}
</style>