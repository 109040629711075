<template>
  <div>
    <p class="  mb-6">Mesas</p>
    <v-row>
      <v-col>
        <view-mesas></view-mesas>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import ViewMesas from './componenetes/ViewMesas.vue'
// demos

export default {
  components: {ViewMesas},
  setup() {
    return {}
  },
}
</script>
